.account-flow-layout {
  height: 100vh;
  background: linear-gradient(#000, #534E84, #7E7AAB, #FFF);
  background-image: url('../../assets/main-background-img.png'); /* This will be removed */
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;

  > header {
    text-align: center;
    padding: 48px 12px;

    img.korio-logo {
      height: 72px;
      width: auto;
    }
  }

  > section {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(48px + 72px + 48px);

    .wrapper {
      backdrop-filter: saturate(180%) blur(10px);
      background-color: #FFF;
      padding: 32px;
      border-radius: 8px;
      border: 1px solid #FFF;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      max-width: 600px;
      overflow: hidden;

      h1 {
        font-size: 38px;
        margin: 0;
        color: #534E84;
      }

      p {
        font-size: 16px;
        line-height: normal;
        color: #527F8E;
      }

      > div {
        width: 600px;

        > section {
          .input-wrapper {
            position: relative;
            margin: 48px 0 24px 0;

            input {
              font-size: 32px;
              font-weight: bold;
              outline: none;
              border: none;
              border-bottom: 6px solid #534E84;
              background: transparent;
              padding: 0 42px 0 0;
              width: calc(100% - 42px);

              &:focus {
                border-color: #005FCC;
              }

              &:disabled {
                opacity: 0.5;
                padding: 0;
                width: 100%;
              }
            }

            .btn-next {
              position: absolute;
              cursor: pointer;
              right: 0;
              bottom: 6px;
              height: 42px;
              width: 42px;
              display: flex;
              justify-content: center;
              align-items: center;

              > svg {
                height: 32px;
                width: 32px;
                opacity: 0.25;
                transition: opacity 150ms;
              }

              &:hover {
                > svg {
                  opacity: 0.75;
                }
              }
            }
          }

          .checkbox-wrapper {
            a {
              font-weight: bold;
              text-decoration: none;
              color: inherit;

              &:hover {
                color: #534e84;
              }
            }
          }

          p.action-text {
            font-weight: bold;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          footer {
            font-size: 12px;
            color: #527F8E;

            &.action-text {
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    } 
  }
}
