#create-pin-page {
  > header {
    display: flex;
    align-items: flex-end;
  }

  > section {
    .inputs-container {
      display: flex;
      justify-content: space-around;
      padding: 32px 12px;

      input {
        width: 10%;
        font-size: 48px;
        text-align: center;
        outline: none;
        border: none;
        border-bottom: 6px solid #534E84;
        background: transparent;

        &:focus {
          border-color: #005FCC;
        }
      }
    }
  }
}
